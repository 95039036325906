import React, {useEffect,useState} from "react";
import {BreadcrumbAndHeading, ShowOrderDetail} from '../../../components';
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { useNavigate, useLocation } from "react-router-dom";
import { clientOrderCustomFetch } from "../../../utils/axios";
import { HandleUnauthorization } from "../../../utils/HandleUnauthorization";

const SalesOrderListDetail = () => {

  const secretKey = 'NAFFCO2023@GALAXY';
  const search = useLocation().search;
  const rowId = new URLSearchParams(search).get('r');
  const navigate = useNavigate();
  // let requestRow = [];

  const [orderDetailData, setOrderDetailData] = useState([]);
  const [orderDetailStatusData, setOrderDetailStatusData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [isLogout, setIsLogout] = useState(false);
  const [enquiryName, setEnquiryName] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');
  useEffect(() => {
    if (rowId === null) {
      navigate('/sales-order-list');
    } else {
        let rowIdDecrypt = AES.decrypt(rowId, secretKey);
        //alert(rowIdDecrypt)
        if (rowIdDecrypt == "") {
            navigate('/sales-order-list');
        }
        else {
            const requestRowId = JSON.parse(rowIdDecrypt.toString(Utf8));
            const requestRowIdData = JSON.parse(localStorage.getItem('order_data'));
            
            fetchOrderDetail(requestRowId)
            setOrderDetailData(requestRowIdData)
        }
      }
    }, [rowId]);
    
    const fetchOrderDetail = async (requestRowId) => {
      setIsLoading(true);
      try {
        const requestData = {
          //id: 75490,
          typeOfUser: "Naffco",
          id: requestRowId,
          customerCode:"",
        };
        const response = await clientOrderCustomFetch.post(`/getOrderStatusHistory.html`, requestData);
        
          const { url, data, success, message } = response.data;
          if (success) {
            setIsLoading(false);

              if(data.length > 0) {
                const deliveredStatus = data.find((status) => status.status === "Dispatched");

                // Check if 'Delivered' status has a date
                const orderStatusText = deliveredStatus?.date ? "Order Closed" : "Order Open";
                setOrderStatus(orderStatusText)
                setOrderDetailStatusData(data);
                //setDownloadUrl(url || 'https://icseindia.org/document/sample.pdf')
            }
          }
          //setDownloadUrl(url || 'https://icseindia.org/document/sample.pdf')
          
      } catch (err) {
        setIsLoading(false);
        if (err.response?.status === 401) {
          setIsLogout(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <>
      <div className='container'>
        <div className='sales_main'>
          <BreadcrumbAndHeading name={orderDetailData.salesOrder} classShows="UploadOfferShow" preUrl="Sales Order List View" url="/sales-order-list" downloadUrl={downloadUrl}/>
          <div className='order_track'>
            <ShowOrderDetail orderDetailData={orderDetailData} orderDetailStatusData={orderDetailStatusData} isLoading={isLoading} orderStatus={orderStatus}/>
          </div>
        </div>
      </div>
      </>
    )
  }
  
export default SalesOrderListDetail