import React, { useState, useEffect } from "react";
// import { FormRow } from '../../components';
import customFetch from "../../../utils/axios";
import { EnquiriesHead, FormFileSelect, UploadFile } from '../../../components';
import endSuction from '../../../assets/images/endSuctionSelected.jpg';
import horizontalPump from '../../../assets/images/horizontal-pump.png';
import verticalPump from '../../../assets/images/vertical-pump.png';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ToastMessage from '../../../components/ToastMessage';
import { FlowUnits, HeadUnits, Certification, DriverType, CountryList } from '../../../utils/clientMasterAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import { searchCriteria, setPumpSearchValues, clearValues, updateAPIStatus } from '../../../features/pumpSelection/pumpSelectionSlice';
import { useForm } from "react-hook-form";
import { HandleUnauthorization } from "../../../utils/HandleUnauthorization";
import ShowError from '../../../components/ShowError';


const initialStateError = [{
    flow_error: '',
    head_error: '',
    certification_error: '',
    nfpa_error: '',
    minSpeed_error: '',
    maxSpeed_error: '',
    driverType_error: '',
}];

const initialState = [{
    id: 0,
    flow: '',
    flowUOM: '',
    head: '',
    headUOM: '',
    certification: '',
    nfpa: true,
    minSpeed: '2500',
    maxSpeed: '3000',
    driverType: ''
}];

const initialSelectPump = {
    request_param: '',
    name: '',
    image: '',
    list_image: ''
};


const AddNewPump = () => {

    const navigate = useNavigate();

    const { string } = useParams();

    const { register, handleSubmit, formState: { errors }, setError, setValue, clearErrors } = useForm();

    const [selected_file, setSelectedFile] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState('');
    const [isLogout, setIsLogout] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const [flowUnitsData, setflowUnitsData] = useState([]);
    const [errorText, setErrorText] = useState(initialStateError);
    const [headUnitsData, setHeadUnitsData] = useState([]);
    const [certificationData, setCertificationData] = useState([]);
    const [driverTypeData, setDriverTypeData] = useState([]);
    const [values, setValues] = useState(initialState);
    const [countryData, setCountryData] = useState([]);
    const [countryValue, setCountryValue] = useState('');
    const [countryText, setCountryText] = useState('');
    const [countryValueJson, setCountryValueJson] = useState('');
    const [selectPumpValues, setSelectPumpValues] = useState(initialSelectPump);

    // form 
    const [code, setCode] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [model, setModel] = useState('');
    const [flow, setFlow] = useState('');
    const [flowUom, setFlowUom] = useState('');
    const [pressure, setPressure] = useState('');
    const [pressureUom, setPressureUom] = useState('');
    const [frequencyData, setFrequencyData] = useState([50, 60]);
    const [frequency, setFrequency] = useState('');
    const [maxPower, setMaxPower] = useState('');
    const [primeMoverRatingKw, setPrimeMoverRatingKw] = useState('');
    const [primeMoverRatingHp, setPrimeMoverRatingHp] = useState('');
    const [selectedPumpfile, setSelectedPumpfile] = useState(false);
    const [certification, setCertifications] = useState('');
    const [pumpType, setPumpType] = useState('');

    const [speed, setSpeed] = useState('');

    const [shutOffPressure, setShutOffPressure] = useState('');
    const [dutyEfficiency, setDutyEfficiency] = useState('');
    const [dutyPower, setDutyPower] = useState('');

    const [peakPower, setPeakPower] = useState('');
    const [flowValue, setFlowValue] = useState('');
    const [suctionDischarge, setSuctionDischarge] = useState('');

    // const [hertz, setHertz] = useState('');
    // const [curve, setCurve] = useState('');
    // const [bkw, setBkw] = useState('');
    const [driver_details, setDriverDetails] = useState('');
    const [stage, setStage] = useState('');
    const [maxString, setMaxString] = useState(20);

    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedPumpSubmitalfile, setSelectedPumpSubmitalfile] = useState(false);

    const [editPumpSubmitalfile, setEditPumpSubmitalfile] = useState('');
    const [editPumpTechnicalfile, setEditPumpTechnicalfile] = useState('');


    const dispatch = useDispatch();

    const { isRedirectfromListing, flow_api_data, head_api_data, certification_api_data, driver_type_api_data, country_api_data, selection_criteria, selected_country, status_api, message_api } =
        useSelector((store) => store.pumpSelection);


    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setApiErrorMessage('');
        setSelectedFile(false)
    }

    const selectedUploadFile = (file) => {
        setSelectedFile(file);
    }

    const selectedfile = (file) => {
        // setSelectedFile(file);
        clearErrors('selectFile');
        setSelectedPumpfile(file)
    }

    const selectedfileSubmital = (file) => {
        // setSelectedFile(file);
        clearErrors('selectSubmitalFile');
        setSelectedPumpSubmitalfile(file)
    }

    const editPump = async () => {

        // Create FormData object
        const formData = new FormData();

        formData.append('pump_id', string);

        try {
            const response = await customFetch.post('/edit-pump', formData);
            const { status, message, error, result } = response.data;
            if (status === true) {

                let result = response.data.data;

                let shut_off_pressure_val = result.shut_off_pressure == null ? null : result.shut_off_pressure.replace(/Bar/g, '').trim();
                let duty_power_val = result.duty_power == null ? null : result.duty_power.replace(/Hp/g, '').trim();

                let peak_power_val = result.peak_power == null ? null : result.peak_power.replace(/Hp/g, '').trim();
                let flow_value_val = result.flow_value == null ? null : result.flow_value.replace(/USGPM/g, '').trim();
                let suction_discharge_size_val = result.suction_discharge_size == null ? null : result.suction_discharge_size.replace(/Inch/g, '').trim();



                setValue("code", result.code);
                setValue("itemCode", result.item_code);
                setValue("model", result.model);

                setValue("primemover_typr", result.primemover_type);
                setValue("is_visible", result.is_visible);

                setValue("pump_type", result.pump_type);
                setPumpType(result.pump_type);

                setValue("flow", result.flow);
                setValue("pressure", result.pressure);

                let flowUnitsDataValue = sessionStorage.getItem("flowUnitsData").split(',');
                let headUnitsDataValue = sessionStorage.getItem("headUnitsData").split(',');
                let certificationDataValue = sessionStorage.getItem("certificationData").split(',');

                setFlowUom(Object.keys(flowUnitsDataValue).find(key => flowUnitsDataValue[key] === result.flow_uom))
                setPressureUom(Object.keys(headUnitsDataValue).find(key => headUnitsDataValue[key] === result.pressure_uom))
                setCertifications(Object.keys(certificationDataValue).find(key => certificationDataValue[key] === result.certification))


                setValue("controller", result.controller);
                setValue("frequency", result.frequency);
                setValue("max_power", result.max_power);

                setValue("prime_mover_rating_kw", result.primemover_rating_kw);
                setValue("prime_mover_rating_hp", result.primemover_rating_hp);
                setValue("speed", result.speed);

                setValue("shut_off_pressure", shut_off_pressure_val);
                setValue("duty_efficiency", result.duty_efficiency);
                setValue("duty_power", duty_power_val);

                setValue("peak_power", peak_power_val);
                setValue("flow_value", flow_value_val);
                setValue("suction_discharge", suction_discharge_size_val);

                setValue("hertz", result.hertz);
                // setValue("curve", result.curve);
                // setValue("bkw", result.bkw);
                setValue("driver_details", result.driver_details);

                setValue("stage", result.stage);

                let submital_file = result.submital.replace('pump/submital/', '').trim();
                let technical_file = result.technical_data_sheet.replace('pump/technical_sheet/', '').trim();

                setEditPumpSubmitalfile(submital_file)
                setEditPumpTechnicalfile(technical_file)

            } else {
                setSelectedFile(false)
                setApiErrorMessage(message);
            }
        } catch (err) {
            // if (err.response.status === 401) {
            //   setIsLogout(true)
            // }

            setErrorMessage('There is something wrong. Please try again');
            //window.scrollTo(0,0);
        }

    };


    useEffect(() => {
        commonFunction();
    }, [string]);

    const commonFunction = async () => {
        masterApiCall()
        setAPIData()
        await editPump()
    }


    async function masterApiCall() {

        const getFlowUnitData = await FlowUnits();
        if (getFlowUnitData[0]) {
            setflowUnitsData(getFlowUnitData[1])
            sessionStorage.setItem("flowUnitsData", getFlowUnitData[1]);
            setValues(values => {
                return values.map(value => {
                    return value.id === 0 ? { ...value, 'flowUOM': getFlowUnitData[1][0] } : value
                })
            });
        }
        const getHeadUnitData = await HeadUnits();
        if (getHeadUnitData[0]) {
            const updatedArray = getHeadUnitData[1].map((element) => element.toUpperCase());
            setHeadUnitsData(updatedArray)
            sessionStorage.setItem("headUnitsData", updatedArray);
            setValues(values => {
                return values.map(value => {
                    return value.id === 0 ? { ...value, 'headUOM': getHeadUnitData[1][0] } : value
                })
            });
        }

        const getCertificationData = await Certification();
        if (getCertificationData[0]) {
            setCertificationData(getCertificationData[1])
            sessionStorage.setItem("certificationData", getCertificationData[1]);
            setValues(values => {
                return values.map(value => {
                    return value.id === 0 ? { ...value, 'certification': getCertificationData[1][0] } : value
                })
            });
        }

        const getDriverTypeData = await DriverType();
        if (getDriverTypeData[0]) {
            setDriverTypeData(getDriverTypeData[1])

            setValues(values => {
                return values.map(value => {
                    return value.id === 0 ? { ...value, 'driverType': getDriverTypeData[1][0] } : value
                })
            });

        }
        const getCountryListData = await CountryList();
        if (getCountryListData[0]) {
            setCountryData(getCountryListData[1])

            setCountryValue(getCountryListData[1][0].code)

            const newItem = getCountryListData[1].filter((cData) => {
                return cData.code === getCountryListData[1][0].code;
            });

        }
    }




    const setAPIData = () => {

        setflowUnitsData(flow_api_data)
        setHeadUnitsData(head_api_data)
        setCertificationData(certification_api_data)
        setDriverTypeData(driver_type_api_data)
        setCountryData(country_api_data)
        //alert(JSON.stringify(selection_criteria))
        setValues(JSON.parse(JSON.stringify(selection_criteria)))
        setCountryValue(selected_country)
        const newItem = country_api_data.filter((cData) => {
            return cData.code === selected_country;
        });
    }



    const onSubmit = async (data) => {

        setIsLoading(true);

        // let data_flow_uom;
        // let data_pressure_uom;
        let data_certification;

        // if(typeof data.flow_uom !== "undefined") {
        //     data_flow_uom =  flowUnitsData[data.flow_uom];
        // } else {
        //     data_flow_uom =  flowUnitsData[0]; 
        // }

        // if(typeof data.pressure_uom !== "undefined" ) {
        //     data_pressure_uom =  headUnitsData[data.pressure_uom];
        // } else {
        //     data_pressure_uom =  headUnitsData[0]; 
        // }

        if (data.certification !== '') {
            data_certification = certificationData[data.certification];
        } else {
            data_certification = certificationData[0];
        }

        let showErrors = 0;

        // Technical sheet
        if (selectedPumpfile !== false) {
            if (selectedPumpfile.size > 8000000) {
                setError('selectFile', {
                    type: "custom",
                    message: 'Selected file size should be below 8MB.'
                });
                showErrors = 1;
            } else if (selectedPumpfile.type !== 'application/pdf') {
                setError('selectFile', {
                    type: "custom",
                    message: 'Please select valid file.'
                });
                showErrors = 1
            }

        }

        // Submittal data

        if (selectedPumpSubmitalfile !== false) {
            if (selectedPumpSubmitalfile.size > 80000000) {
                setError('selectSubmitalFile', {
                    type: "custom",
                    message: 'Selected file size should be below 80MB.'
                });
                showErrors = 1;
            } else if (selectedPumpSubmitalfile.type !== 'application/pdf') {
                setError('selectSubmitalFile', {
                    type: "custom",
                    message: 'Please select valid file.'
                });
                showErrors = 1
            }
        }

        if (showErrors === 1) {
            setIsLoading(false);
            return false;
        }


        // console.log(selectedPumpfile);
        // console.log(selectedPumpSubmitalfile); return false;

        const formData = new FormData();

        formData.append("id", string);

        formData.append("code", data.code);
        formData.append("item_code", data.itemCode);
        formData.append("model", data.model);
        formData.append("pump_type", data.pump_type);
        formData.append("is_visible", data.is_visible);
        formData.append("primemover_typr", data.primemover_typr);
        formData.append("flow", data.flow);
        formData.append("flow_uom", 'USGPM');
        formData.append("pressure", data.pressure);
        formData.append("pressure_uom", 'BAR');
        formData.append("certification", data_certification);
        formData.append("controller", data.controller);
        formData.append("frequency", data.frequency);
        formData.append("max_power", data.max_power);
        formData.append("prime_mover_rating_kw", data.prime_mover_rating_kw);
        formData.append("prime_mover_rating_hp", data.prime_mover_rating_hp);

        formData.append("speed", data.speed);
        formData.append("shut_off_pressure", data.shut_off_pressure);
        formData.append("duty_efficiency", data.duty_efficiency);
        formData.append("duty_power", data.duty_power);
        formData.append("peak_power", data.peak_power);
        formData.append("flow_value", data.flow_value);
        formData.append("suction_discharge", data.suction_discharge);
        //formData.append("hertz", data.hertz);
        // formData.append("curve", data.curve);
        // formData.append("bkw", data.bkw);
        formData.append("driver_details", data.driver_details);
        formData.append("stage", data.stage);
        formData.append("selectedPumpfile", selectedPumpfile);
        formData.append("selectedPumpSubmitalfile", selectedPumpSubmitalfile);

        try {
            const response = await customFetch.post('/update-pump', formData);
            const { status, message, error, result } = response.data;
            if (status === true) {
                setApiSuccessMessage(message);
                sessionStorage.setItem('success_msg', message);
                navigate('/stock-pump-master');
            } else {
                setIsLoading(false);
                if (error) {
                    if (error['code']) {
                        setError('code', {
                            type: "custom",
                            message: error['code']
                        });
                        window.scrollTo(0, 0);
                    }

                    if (error['item_code']) {
                        setError('itemCode', {
                            type: "custom",
                            message: error['item_code']
                        });
                        window.scrollTo(0, 0);
                    }

                    if (error['selectedPumpfile']) {
                        setError('selectFile', {
                            type: "custom",
                            message: error['selectedPumpfile']
                        });
                    }

                    if (error['selectedPumpSubmitalfile']) {
                        setError('selectSubmitalFile', {
                            type: "custom",
                            message: error['selectedPumpSubmitalfile']
                        });
                    }
                }
            }
        } catch (err) {
            setIsLoading(false);

            // if (err.response.status === 401) {
            //   setIsLogout(true)
            // }

            setErrorMessage('There is something wrong. Please try again');
            //window.scrollTo(0,0);
        }


    };

    const cancalForm = () => {
        navigate('/stock-pump-master');
    }



    return (
        <>
            <div className='Add_new_pump_block'>
                <div className='container'>
                    <div className="table-header table_heading_head">
                        <div className="table_heading_content">
                            <h1 className="p-m-0">Edit Pump</h1>
                            {/* <div className="conditional_block_show">
                                <button className='darkred_btn' onClick={handleShow} >
                                    <span>Upload</span>
                                </button>
                            </div> */}


                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="new_pump_details">
                            <div className="row">

                                <div className="col-md-4 w_991_full px_10">
                                    <div className="form-row">
                                        <label>Item Code <span className="error_text">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="itemCode"
                                            onChange={(e) => setItemCode(e.target.value)}
                                            {...register("itemCode", {
                                                required: 'Please enter item code.',
                                                maxLength: {
                                                    value: maxString,
                                                    message: 'Max length exceeded.'
                                                }
                                            })}
                                        />
                                        <ShowError errorsModal={errors.itemCode} />

                                    </div>
                                </div>

                                <div className="col-md-4 w_991_full px_10">
                                    <div className="form-row">
                                        <label>Code <span className="error_text">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="code"
                                            onChange={(e) => setCode(e.target.value)}
                                            {...register("code", {
                                                required: 'Please enter code.',
                                                maxLength: {
                                                    value: maxString,
                                                    message: 'Max length exceeded.'
                                                },
                                                pattern: {
                                                    value: /^[0-9]+$/i,
                                                    message: 'Please enter valid code.'
                                                }
                                            })}
                                        />
                                        <ShowError errorsModal={errors.code} />
                                    </div>
                                </div>

                                <div className="col-md-4 w_991_full px_10">
                                    <div className="form-row">
                                        <label>Model <span className="error_text">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="model"
                                            onChange={(e) => setModel(e.target.value)}
                                            {...register("model", {
                                                required: 'Please enter model.',
                                                maxLength: {
                                                    value: maxString,
                                                    message: 'Max length exceeded.'
                                                }
                                            })}
                                        />
                                        <ShowError errorsModal={errors.model} />
                                    </div>
                                </div>

                                <div className="col-md-4 w_991_full px_10">&nbsp;</div>

                            </div>
                            <div className="row">

                                <div className="col-md-4 w-max-991_col_50 px_10 ">
                                    <div className="checkbox_main form-row">
                                        <label>Driver Type <span className="error_text">*</span></label>
                                        <div className="checkbox_container">
                                            <div className='change_type d-flex'>
                                                <input
                                                    {...register("primemover_typr", { required: 'Please select driver type.' })}
                                                    type="radio"
                                                    value="Motor"
                                                    id="electric"
                                                    className="ml_10"
                                                />
                                                <span htmlFor="electric">Motor</span>
                                            </div>
                                            <div className='change_type d-flex'>
                                                <input
                                                    {...register("primemover_typr", { required: 'Please select driver type.' })}
                                                    type="radio"
                                                    value="Engine"
                                                    id="diesel"
                                                    className="ml_10"
                                                />
                                                <span htmlFor="diesel">Engine</span>
                                            </div>
                                        </div>
                                        <ShowError errorsModal={errors.primemover_typr} />
                                    </div>
                                </div>

                                <div className="col-md-4 w-max-991_col_50 px_10 ">
                                    <div className="visible_toggle form-row new_pump_toggle">
                                        <label>Visibility</label>
                                        <div className="toggle_checkbox">
                                            <input
                                                {...register("is_visible")}
                                                type="checkbox"
                                                value="1"
                                                id="check-2"
                                            />
                                            <label htmlFor="check-2"></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 w_991_full px_10">&nbsp;</div>

                            </div>
                            <div className="row">

                                <div className="col-md-4 w_991_full px_10 ">
                                    <div className="form-row">
                                        <label>Pump Type <span className="error_text">*</span></label>
                                        <div className={pumpType == "End Suction" ? 'selected_pump_shows pump_checked' : 'selected_pump_shows'}>
                                            <div className='selected_pump_img_name'>
                                                <img src={endSuction} alt="pump selected" />
                                                <span className='pump_name_show'>End Suction</span>
                                            </div>
                                            <div className='change_type'>
                                                <input
                                                    {...register("pump_type", { required: 'Please select pump type.' })}
                                                    type="radio"
                                                    value="End Suction"
                                                    id="pump_type_1"
                                                    className='d-block mt_20'
                                                    onChange={(e) => setPumpType(e.target.value)}
                                                />
                                                <label htmlFor="pump_type_1"></label>
                                            </div>

                                        </div>
                                        <ShowError errorsModal={errors.pump_type} />
                                    </div>
                                </div>

                                <div className="col-md-4 w_991_full px_10">
                                    <div className="form-row">
                                        <label className="iPad_label_d-none">&nbsp;</label>
                                        <div className={pumpType == "Splitcase" ? 'selected_pump_shows pump_checked' : 'selected_pump_shows'}>
                                            <div className='selected_pump_img_name'>
                                                <img src={horizontalPump} alt="pump selected" />
                                                <span className='pump_name_show'>Horizontal Splitcase</span>
                                            </div>
                                            <div className='change_type'>
                                                <input
                                                    {...register("pump_type", { required: 'Please select pump type.' })}
                                                    type="radio"
                                                    value="Splitcase"
                                                    id="pump_type_2"
                                                    className='d-block mt_20'
                                                    onChange={(e) => setPumpType(e.target.value)}
                                                />
                                                <label htmlFor="pump_type_2"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 w_991_full px_10">
                                    <div className="form-row">
                                        <label className="iPad_label_d-none">&nbsp;</label>
                                        <div className={pumpType == "Vertical Turbine" ? 'selected_pump_shows pump_checked' : 'selected_pump_shows'}>
                                            <div className='selected_pump_img_name'>
                                                <img src={verticalPump} alt="pump selected" />
                                                <span className='pump_name_show'>Vertical Turbine</span>
                                            </div>
                                            <div className='change_type'>
                                                <input
                                                    {...register("pump_type", { required: 'Please select pump type.' })}
                                                    type="radio"
                                                    value="Vertical Turbine"
                                                    id="pump_type_3"
                                                    className='d-block mt_20'
                                                    onChange={(e) => setPumpType(e.target.value)}
                                                />
                                                <label htmlFor="pump_type_3"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">

                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className='combine_fields'>
                                        <div className="form-row">
                                            <label>Required Pump Flow <span className="error_text">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="flow"
                                                onChange={(e) => setFlow(e.target.value)}
                                                {...register("flow", {
                                                    required: 'Please enter pump flow.',
                                                    maxLength: {
                                                        value: maxString,
                                                        message: 'Max length exceeded.'
                                                    },
                                                    pattern: {
                                                        value: /^[.0-9]+$/i,
                                                        message: 'Please enter valid pump flow.'
                                                    },
                                                })}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <label className="form-label"></label>
                                            <input
                                                type="text"
                                                className="form-control single_box"
                                                value="USGPM"
                                            />
                                        </div>
                                        {/* {(flowUnitsData.length > 0) && (
                                            <div className="form-row">
                                                <label className="form-label"></label>
                                                <select 
                                                        name="flow_uom"
                                                        onChange={ (e) => setFlowUom(e.target.value) }
                                                        {...register("flow_uom",{ required: true } ) } 
                                                        className="form-select"
                                                    >
                                                    { flowUnitsData.map((item, index) => (
                                                        <option value={index}>{item}</option>
                                                    ))}       
                                                </select>
                                            </div>
                                        )} */}
                                    </div>
                                    <ShowError errorsModal={errors.flow} classValue={'float_error'} />
                                </div>

                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className='combine_fields'>
                                        <div className="form-row">
                                            <label>Required Pump pressure <span className="error_text">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="pressure"
                                                onChange={(e) => setPressure(e.target.value)}
                                                {...register("pressure", {
                                                    required: 'Please enter pump pressure.',
                                                    maxLength: {
                                                        value: maxString,
                                                        message: 'Max length exceeded.'
                                                    },
                                                    pattern: {
                                                        value: /^[.0-9]+$/i,
                                                        message: 'Please enter valid pump pressure.'
                                                    },
                                                })}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <label className="form-label"></label>
                                            <input
                                                type="text"
                                                className="form-control single_box"
                                                value="BAR"
                                            />
                                        </div>
                                        {/* {(headUnitsData.length > 0) && (
                                            <div className="form-row">
                                                <label className="form-label"></label>
                                                <select 
                                                    name="pressure_uom"
                                                    onChange={ (e) => setPressureUom(e.target.value) }
                                                    {...register("pressure_uom",{ required: true } ) } 
                                                    className="form-select"
                                                >
                                                { headUnitsData.map((item, index) => (
                                                    <option value={index}>{item}</option>
                                                ))}       
                                                </select>
                                            </div>
                                        )} */}
                                    </div>
                                    <ShowError errorsModal={errors.pressure} classValue={'float_error'} />
                                </div>

                                <div className='col-md-4 px_10 mb_24 w_991_full w-i_Pad_full'>
                                    <div className='checkbox_inner_field'>
                                        <div className="form-row">
                                            <label className="form-label">Certification <span className="error_text">*</span></label>
                                            <select
                                                name="certification"
                                                onChange={(e) => setCertifications(e.target.value)}
                                                {...register("certification", { required: false })}
                                                className="form-select"
                                                value={certification}
                                            >
                                                {certificationData.map((item, index) => (
                                                    <option value={index}>{item}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="form-row">
                                        <label>Controller <span className="error_text">*</span></label>
                                        <div className="checkbox_container">
                                            <div className='change_type d-flex'>
                                                <input
                                                    {...register("controller", { required: 'Please select controller.' })}
                                                    type="radio"
                                                    value="Provided"
                                                    id="provided"
                                                    className="ml_10"
                                                />
                                                <span htmlFor="provided">Provided</span>
                                            </div>
                                            <div className='change_type d-flex'>
                                                <input
                                                    {...register("controller", { required: 'Please select controller.' })}
                                                    type="radio"
                                                    value="Not Provided"
                                                    id="not_provided"
                                                    className="ml_10"
                                                />
                                                <span htmlFor="not_provided">Not Provided</span>
                                            </div>
                                        </div>
                                        <ShowError errorsModal={errors.controller} />
                                    </div>
                                </div>
                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="form-row">
                                        <label>Frequency <span className="error_text">*</span></label>
                                        <select
                                            name="frequency"
                                            onChange={(e) => setFrequency(e.target.value)}
                                            {...register("frequency", { required: 'Please select frequency.', })}
                                            className="form-select"
                                        >
                                            {frequencyData.map((item, index) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </select>
                                        <ShowError errorsModal={errors.frequency} />
                                    </div>
                                </div>

                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="form-row">
                                        <label>Max Power <span className="error_text">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="max_power"
                                            onChange={(e) => setMaxPower(e.target.value)}
                                            {...register("max_power", {
                                                required: 'Please enter max power.',
                                                maxLength: {
                                                    value: maxString,
                                                    message: 'Max length exceeded.'
                                                },
                                                pattern: {
                                                    value: /^[.0-9]+$/i,
                                                    message: 'Please enter valid max power.'
                                                }

                                            })}
                                        />
                                        <ShowError errorsModal={errors.max_power} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="form-row">
                                        <label>Prime Mover Rating (KW) <span className="error_text">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="prime_mover_rating_kw"
                                            onChange={(e) => setPrimeMoverRatingKw(e.target.value)}
                                            {...register("prime_mover_rating_kw", {
                                                required: 'Please enter prime mover rating (kw).',
                                                maxLength: {
                                                    value: maxString,
                                                    message: 'Max length exceeded.'
                                                },
                                                pattern: {
                                                    value: /^[.0-9]+$/i,
                                                    message: 'Please enter valid prime mover rating (kw).'
                                                }
                                            })}
                                        />
                                        <ShowError errorsModal={errors.prime_mover_rating_kw} />
                                    </div>
                                </div>

                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="form-row">
                                        <label>Prime Mover Rating (HP) <span className="error_text">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="prime_mover_rating_hp"
                                            onChange={(e) => setPrimeMoverRatingHp(e.target.value)}
                                            {...register("prime_mover_rating_hp", {
                                                required: 'Please enter prime mover rating (hp).',
                                                maxLength: {
                                                    value: maxString,
                                                    message: 'Max length exceeded.'
                                                },
                                                pattern: {
                                                    value: /^[.0-9]+$/i,
                                                    message: 'Please enter valid prime mover rating (hp).'
                                                }
                                            })}
                                        />
                                        <ShowError errorsModal={errors.prime_mover_rating_hp} />
                                    </div>
                                </div>

                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="form-row">
                                        <label>Speed <span className="error_text">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="speed"
                                            onChange={(e) => setSpeed(e.target.value)}
                                            {...register("speed", {
                                                required: 'Please enter speed.',
                                                maxLength: {
                                                    value: maxString,
                                                    message: 'Max length exceeded.'
                                                },
                                                pattern: {
                                                    value: /^[0-9]+$/i,
                                                    message: 'Please enter valid speed.'
                                                }
                                            })}
                                        />
                                        <ShowError errorsModal={errors.speed} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="combine_fields">
                                        <div className="form-row">
                                            <label>Shut Off Pressure <span className="error_text">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="shut_off_pressure"
                                                onChange={(e) => setShutOffPressure(e.target.value)}
                                                {...register("shut_off_pressure", {
                                                    required: 'Please enter shut off pressure.',
                                                    maxLength: {
                                                        value: maxString,
                                                        message: 'Max length exceeded.'
                                                    },
                                                    pattern: {
                                                        value: /^[.0-9]+$/i,
                                                        message: 'Please enter valid shut off pressure.'
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <label className="form-label"></label>
                                            <input
                                                type="text"
                                                className="form-control single_box"
                                                value="Bar"
                                            />
                                        </div>
                                    </div>
                                    <ShowError errorsModal={errors.shut_off_pressure} classValue={'float_error'} />
                                </div>

                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="combine_fields">
                                        <div className="form-row">
                                            <label>Duty Efficiency <span className="error_text">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="duty_efficiency"
                                                onChange={(e) => setDutyEfficiency(e.target.value)}
                                                {...register("duty_efficiency", {
                                                    required: 'Please enter duty efficiency.',
                                                    maxLength: {
                                                        value: maxString,
                                                        message: 'Max length exceeded.'
                                                    },
                                                    pattern: {
                                                        value: /^[.0-9]+$/i,
                                                        message: 'Please enter valid duty efficiency.'
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <label className="form-label"></label>
                                            <input
                                                type="text"
                                                className="form-control single_box"
                                                value="%"
                                            />
                                        </div>
                                    </div>
                                    <ShowError errorsModal={errors.duty_efficiency} classValue={'float_error'} />
                                </div>

                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="combine_fields">
                                        <div className="form-row">
                                            <label>Duty Power <span className="error_text">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="duty_power"
                                                onChange={(e) => setDutyPower(e.target.value)}
                                                {...register("duty_power", {
                                                    required: 'Please enter duty power.',
                                                    maxLength: {
                                                        value: maxString,
                                                        message: 'Max length exceeded.'
                                                    },
                                                    pattern: {
                                                        value: /^[.0-9]+$/i,
                                                        message: 'Please enter valid duty power.'
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <label className="form-label"></label>
                                            <input
                                                type="text"
                                                className="form-control single_box"
                                                value="Hp"
                                            />
                                        </div>
                                    </div>
                                    <ShowError errorsModal={errors.duty_power} classValue={'float_error'} />
                                </div>

                            </div>
                            <div className="row">
                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="combine_fields">
                                        <div className="form-row">
                                            <label>Peak Power <span className="error_text">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="peak_power"
                                                onChange={(e) => setPeakPower(e.target.value)}
                                                {...register("peak_power", {
                                                    required: 'Please enter pick power.',
                                                    maxLength: {
                                                        value: maxString,
                                                        message: 'Max length exceeded.'
                                                    },
                                                    pattern: {
                                                        value: /^[.0-9]+$/i,
                                                        message: 'Please enter valid pick power.'
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <label className="form-label"></label>
                                            <input
                                                type="text"
                                                className="form-control single_box"
                                                value="Hp"
                                            />
                                        </div>
                                    </div>
                                    <ShowError errorsModal={errors.peak_power} classValue={'float_error'} />
                                </div>

                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="combine_fields">
                                        <div className="form-row">
                                            <label>Flow at 150% <span className="error_text">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="flow_value"
                                                onChange={(e) => setFlowValue(e.target.value)}
                                                {...register("flow_value", {
                                                    required: 'Please enter flow at 150%.',
                                                    maxLength: {
                                                        value: maxString,
                                                        message: 'Max length exceeded.'
                                                    },
                                                    pattern: {
                                                        value: /^[.0-9]+$/i,
                                                        message: 'Please enter valid flow at 150%.'
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <label className="form-label"></label>
                                            <input
                                                type="text"
                                                className="form-control single_box"
                                                value="USGPM"
                                            />
                                        </div>
                                    </div>
                                    <ShowError errorsModal={errors.flow_value} classValue={'float_error'} />
                                </div>

                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="combine_fields">
                                        <div className="form-row">
                                            <label>Suction & Discharge Size <span className="error_text">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="suction_discharge"
                                                onChange={(e) => setSuctionDischarge(e.target.value)}
                                                {...register("suction_discharge", {
                                                    required: 'Please enter suction & discharge size.',
                                                    maxLength: {
                                                        value: maxString,
                                                        message: 'Max length exceeded.'
                                                    },
                                                    pattern: {
                                                        value: /^[&0-9 ]+$/i,
                                                        message: 'Please enter valid suction & discharge size.'
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <label className="form-label"></label>
                                            <input
                                                type="text"
                                                className="form-control single_box"
                                                value="Inch"
                                            />
                                        </div>
                                    </div>
                                    <ShowError errorsModal={errors.suction_discharge} classValue={'float_error'} />
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    {/* <div className="form-row">
                                        <label>Curve</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="curve"
                                            onChange={ (e) => setCurve(e.target.value) }
                                            {...register("curve",{ 
                                                required: false,
                                                maxLength: {
                                                    value : maxString,
                                                    message : 'Max length exceeded.'
                                                },
                                            } ) }
                                        />
                                        <ShowError errorsModal={errors.curve} />
                                    </div>                     */}
                                    <div className="form-row">
                                        <label>Driver Details</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="driver_details"
                                            onChange={(e) => setDriverDetails(e.target.value)}
                                            {...register("driver_details", {
                                                required: false,
                                                maxLength: {
                                                    value: 200,
                                                    message: 'Max length exceeded.'
                                                },
                                            })}
                                        />
                                        <ShowError errorsModal={errors.driver_details} />
                                    </div>
                                </div>

                                <div className='col-md-4 px_10 mb_24 w_991_full'>
                                    <div className="form-row">
                                        <label>Stage</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="stage"
                                            onChange={(e) => setStage(e.target.value)}
                                            {...register("stage", {
                                                required: 'Please enter stage.',
                                                maxLength: {
                                                    value: maxString,
                                                    message: 'Max length exceeded.'
                                                },
                                                pattern: {
                                                    value: /^[&0-9 ]+$/i,
                                                    message: 'Please enter valid stage.'
                                                }
                                            })}
                                        />
                                        <ShowError errorsModal={errors.stage} />
                                    </div>                    
                                </div>


                            </div>



                            <div className="row">
                                <div className="col-md-6 px_10 w_991_full">
                                    <div className="add_new_pump_pdf">
                                        <FormFileSelect labelName="Upload Technical Data Sheet" id="technical" selectedfile={selectedfile} editFileName={editPumpTechnicalfile} />
                                        <ShowError errorsModal={errors.selectFile} classValue={'float_error'} />
                                    </div>
                                </div>
                                <div className="col-md-6 px_10 w_991_full">
                                    <div className="add_new_pump_pdf">
                                        <FormFileSelect labelName="Submital" id="submital" selectedfileSubmital={selectedfileSubmital} editFileName={editPumpSubmitalfile} />
                                        <ShowError errorsModal={errors.selectSubmitalFile} classValue={'float_error'} />
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <div className='bottom_action_btns'>
                            <div className='full_hr'></div>
                            <div className="add_new_pump_btns">
                                <button className='transparent_btn' onClick={cancalForm}>
                                    <span>Cancel</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                                <button className='darkred_btn' type="submit" disabled={isLoading} >
                                    <span>{isLoading ? 'loading...' : 'Update Pump'} </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {
                (isLogout) && (<HandleUnauthorization />)
            }
        </>
    );
}
export default AddNewPump