import React, { useState, useEffect, useRef } from "react";
import { SalesOrderDatatableField, EnquiriesHead } from '../../../components';
import { clientOrderCustomFetch } from "../../../utils/axios";
import { HandleUnauthorization } from "../../../utils/HandleUnauthorization";
import { Paginator } from 'primereact/paginator';
import Loader from '../../../components/Loading';

const SalesOrderList = () => {
  const [orderList, setOrderList] = useState([]);
  const [pagelimit, setPageLimit] = useState(25);
  const [pageNo, setPageNo] = useState(0);
  const [isLogout, setIsLogout] = useState(false);
  const [orderFilters, setOrderFilters] = useState({
    offerNo: '',
    customerName: '',
    salesOrderNo: '',
  });
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  
  useEffect(() => {
    localStorage.removeItem('order_data')
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true)
    fetchOrder();
  }, [pageNo, orderFilters]);

  const fetchOrder = async () => {
    setLoading(true);
    try {
      const requestData = {
        typeOfUser: "Naffco",
        customerCode:"",
        ...orderFilters,
        start: pageNo,
        limit: pagelimit,
      };
      const response = await clientOrderCustomFetch.post(`/getOrderList.html`, requestData);
      const { total, data, success, message } = response.data;
      if (success) {
        setIsLoading(false);
        setOrderList(data);
        
        if(data.length > 0)
          setTotalRecord(total); // Adjust this based on API response if needed
        else
          setTotalRecord(0);
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response?.status === 401) {
        setIsLogout(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const changePage = (page = 1) => {
    setPageNo(page);
  };

  const updateOrderFilters = (filterData) => {
    setPageNo(0)
    setOrderFilters(filterData);
  };

  return (
    <div className='container'>
      <div className='sales_main sale_order_list_view'>
        
        <EnquiriesHead heading="Sales Order List View" styleToSee={{marginRight:'15px'}}  updateOrderFilters={updateOrderFilters}   />
          <SalesOrderDatatableField
            callFrom="sales_order"
            addOrEditMode="Edit"
            dataList={orderList}
            callBackList={fetchOrder}
            changePage={changePage}
            pagelimit={pagelimit}
            totalRecord={totalRecord}
            isLoading={isLoading}
          />
      </div>
    </div>
  );
};

export default SalesOrderList;