import axios from 'axios';
import { clearStore } from '../features/user/userSlice';
import { getUserFromLocalStorage } from './localStorage';

const customFetch = axios.create({
  baseURL: 'https://naffcoapi.pumpsearch.com/api',
  // baseURL: 'http://localhost:8000/api',
  header: {
    'Access-Control-Allow-Origin' :'*'
  }
});


// export const mediaFetch = ()=>{
//   return 'http://localhost:8000/';

// };
// export const mediaFetch = 'http://localhost:8000/';
export const mediaFetch = 'https://naffcoapi.pumpsearch.com/';


customFetch.interceptors.request.use((config) => {
  const user = getUserFromLocalStorage();
  if (user) {
    config.headers['Authorization'] = `Bearer ${user.access_token}`;
  }
  return config;
});

export const clientCustomFetch = axios.create({
  //baseURL: 'http://naffcotest.pumpsearch.com:8080/PumpManagement_V2_Naffconew/api/v1',
  baseURL: 'https://test.pumpsearch.com/PumpManagement_V2_Naffconew/api/v1',
  header: {
    'Access-Control-Allow-Origin' :'*'
  }
});

export const clientOrderCustomFetch = axios.create({
  //baseURL: 'http://naffcotest.pumpsearch.com:8080/PumpManagement_V2_Naffconew/api/v1',
  baseURL: 'https://test.pumpsearch.com/PumpManagement_V2_Naffconew/ordertrackingapi/v1',
  header: {
    'Access-Control-Allow-Origin' :'*'
  }
});


export const checkForUnauthorizedResponse = (error, thunkAPI) => {
  if (error.response.status === 401) {
    thunkAPI.dispatch(clearStore());
    return thunkAPI.rejectWithValue('Unauthorized! Logging Out...');
  }
  return thunkAPI.rejectWithValue(error.response.data.msg);
};

export default customFetch;
