import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { SplitButton } from "primereact/splitbutton";
import { useNavigate } from 'react-router-dom';
import PopupModalEdit from "./PopupModalEdit";
import PopupModalDelete from "./PopupModalDelete";
import ToastMessage from "./ToastMessage";
import AES from 'crypto-js/aes';

export default function SalesOrderDatatableField({
  callFrom,
  addOrEditMode,
  dataList,
  callBackList,
  changePage,
  pagelimit,
  totalRecord,
  isLoading
}) {
  const dt = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [first, setFirst] = useState(0);
  const secretKey = 'NAFFCO2023@GALAXY';
  const navigate = useNavigate();
  const items = (data) => [
    {
      label: "Edit",
      command: () => {
        setSelectedRow(data);
        setApiSuccessMessage('');
        setIsEditOpen(true);
      },
    },
    {
      label: "Delete",
      command: () => {
        setSelectedRow(data);
        setApiSuccessMessage('');
        setIsDeleteOpen(true);
      },
    },
  ];



  const onPageChange = (event) => {
    setFirst(event.first);
    changePage(event.page + 1);
  };

  const onCloseCallBack = (message = null) => {
    setApiSuccessMessage('');
    if (message) {
      setApiSuccessMessage(message);
      callBackList();
    }
    setIsEditOpen(false);
    setIsDeleteOpen(false);
  };

  const redirectToDetail = (rowData) => {
    // alert(rowId.id)
    localStorage.setItem('order_data', JSON.stringify(rowData))
    const rowIdEncryptedString = AES.encrypt(JSON.stringify(rowData.id), secretKey).toString();
    let finalUrl = 'r=' + encodeURIComponent(rowIdEncryptedString);
    navigate('/sales-order-detail?' + finalUrl);
  }

  const eyesShows = (rowData) => (

    <a href="javascript:;" onClick={() => { redirectToDetail(rowData) }}>
      <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.58 12C15.58 13.98 13.98 15.58 12 15.58C10.02 15.58 8.42004 13.98 8.42004 12C8.42004 10.02 10.02 8.41998 12 8.41998C13.98 8.41998 15.58 10.02 15.58 12Z" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39997C18.82 5.79997 15.53 3.71997 12 3.71997C8.46997 3.71997 5.17997 5.79997 2.88997 9.39997C1.98997 10.81 1.98997 13.18 2.88997 14.59C5.17997 18.19 8.46997 20.27 12 20.27Z" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </a>
  );

  const salesStatus = (rowData) => {
    const statusMap = {
      "Work Order Created": { color: "#F57E20", text: "Work Order Created" },
      "Under Production": { color: "#5D5D70", text: "Under Production" },
      "Production Completed": { color: "#9CC559", text: "Production Completed" },
      "Ready for Delivery": { color: "#21BA31", text: "Ready for Delivery" }, // Light Green
      "Dispatched": { color: "#1E90FF", text: "Dispatched" }, // Blue
      "Delivered": { color: "#008000", text: "Delivered" }, // Dark Green
    };
  
    const { currentStatus } = rowData;
  
    // Handle blank or undefined status
    if (!currentStatus || currentStatus.trim() === "") {
      return <div className="status_shows sales_status_text">N/A</div>;
    }
  
    // Check if the current status exists in the statusMap
    if (statusMap[currentStatus]) {
      const { color, text } = statusMap[currentStatus];
      return (
        <div className="status_shows sales_status">
          <svg width="8" height="8">
            <circle cx="4" cy="4" r="4" fill={color} />
          </svg>
          <div className="sales_status_text">{text}</div>
        </div>
      );
    }
  
    // Default case for unrecognized statuses
    return <div className="status_shows sales_status_text">N/A</div>;
  };
  return (
    <>
      {apiSuccessMessage && <ToastMessage showHidePanel={true} isError={0} message={apiSuccessMessage} />}

      <div className="custom_dataTable">
        <DataTable
          ref={dt}
          value={dataList}
          dataKey="id"
          loading={isLoading} // <-- Loader indicator

          emptyMessage={isLoading ? "Loading..." : "No records found"} // Display message based on loading state
        >
          <Column field="salesOrder" header="Order ID" sortable />
          <Column field="projectLocation" header="Location" />
          <Column field="customer" header="Pump Purchase" sortable />
          <Column field="offerNo" header="Offer No" sortable />
          <Column body={salesStatus} field="currentStatus" header="Status" sortable />
          <Column body={eyesShows} field="action" header="Action" sortable className="dots_col" style={{ textAlign: 'right', width: '50px', minWidth: '50px' }}></Column>
        </DataTable>
      </div>

      {totalRecord > 0 && (
        <div className="card">
          <Paginator first={first} rows={pagelimit} totalRecords={totalRecord} onPageChange={onPageChange} />
        </div>
      )}

      {isEditOpen && <PopupModalEdit callFrom={callFrom} addOrEditMode={addOrEditMode} onCloseCallBack={onCloseCallBack} rowdata={selectedRow} />}
      {isDeleteOpen && <PopupModalDelete rowdata={selectedRow} onCloseCallBack={onCloseCallBack} callFrom={callFrom} />}
    </>
  );
}
