import React from "react"; 
import loaderImage from '../assets/images/loader.gif';

const ShowOrderDetail = ({orderDetailData, orderDetailStatusData, isLoading, orderStatus}) => {
  const handleDownload = (url) => {
    // Create an anchor element
    const link = document.createElement('a');
    link.href = url;
    link.download = ''; // Set this if you want a specific filename
    link.target = '_blank'; // Opens in a new tab, optional
    document.body.appendChild(link);
    link.click(); // Programmatically click the link
    document.body.removeChild(link); // Clean up
  };
  
    //console.log(JSON.stringify(orderDetailStatusData))
    return(
        <>  
          {
              (isLoading) && (<div className='show_loader'>  <img src={loaderImage} /> </div>)
            }
            <div className="details_block">
                <h3 className="sub_details_head">ORDER DETAILS</h3>
                <div className="inner_details">
                    <div className="row">
                      <div className="col-md-4 ipad_col_50_100">
                        <div className="form-row">
                          <label>Order ID</label>
                          <p className="details_data align_btn"> {orderDetailData?.salesOrder ? orderDetailData.salesOrder : 'N/A'}
                          {
                            (orderStatus === 'Order Open') && (<span className="order_open">ORDER OPEN</span>)
                          }
                          {
                            (orderStatus === 'Order Closed') && (<span className="order_closed">ORDER Closed</span>)
                          }
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 ipad_col_50_100">
                        <div className="form-row">
                          <label>PUMP DUTY POINT</label>
                          <p className="details_data">
                          {orderDetailData?.head ?? 'N/A'} {orderDetailData?.headUOM ?? 'N/A'} @ {orderDetailData?.flow ?? 'N/A'}  {orderDetailData?.flowUOM ?? 'N/A'}
                          </p>
                          {/* 100 GPM @ 81 M */}
                        </div>
                      </div>
                      <div className="col-md-4 ipad_col_50_100">
                        <div className="form-row">
                          <label>LOCATION</label>
                          <p className="details_data">
                          {orderDetailData?.projectLocation ?? ''}
                            {/* Botswana */}
                            </p>
                        </div>
                      </div>
                        <div className="col-md-4 ipad_col_50_100">
                          <div className="form-row">
                            <label>PUMP PURCHASER</label>
                            <p className="details_data">
                            {orderDetailData?.customer ?? ''}
                              {/* Eclipse International Trading LLC */}
                              </p>
                          </div>
                        </div>
                        <div className="col-md-8  iPad_col_100">
                          <div className="form-row">
                            <label>PROJECT</label>
                            <p className="details_data">
                            {orderDetailData?.project ?? ''}
                              {/* Botswana Embassy chancery office and diplomated Apartment building */}
                              </p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

          {
            (orderDetailStatusData.length > 0) && ( <div className="trackStatus_block">
            <h3 className="sub_details_head">TRACKING STATUS</h3>
            <div className="steps_flow track_flow">
            <ul className="progress_bar">
              
            {orderDetailStatusData?.map((status, index) => {
              // Check if the current status has a date
              const isActive = status.date && status.seq <= Math.max(...orderDetailStatusData.map((s) => s.seq));

              return (
                <li
                  key={index}
                  className={isActive || (orderDetailStatusData[index + 1]?.date ? true : false) ? "active" : ""}
                >
                  <div className="step_data">
                    <p className="step_info">{status.status}</p>
                    {status.date && <p className="step_date">{status.date}</p>}
                    {status.remarks && <p className="step_remarks">{status.remarks}</p>}
                  </div>
                  <span className="fix_border"></span>
                    {
                      (status.document) && (<button className="transparent_btn download_btn new_download_btn" onClick={() => handleDownload(status.document)}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 7.33337V11.3334L7.33333 10" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M5.99984 11.3333L4.6665 10" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M14.6668 6.66671V10C14.6668 13.3334 13.3335 14.6667 10.0002 14.6667H6.00016C2.66683 14.6667 1.3335 13.3334 1.3335 10V6.00004C1.3335 2.66671 2.66683 1.33337 6.00016 1.33337H9.3335" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M14.6668 6.66671H12.0002C10.0002 6.66671 9.3335 6.00004 9.3335 4.00004V1.33337L14.6668 6.66671Z" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    Download
                  </button> )
                    }
                </li>
              );
            })}
            </ul>
                {/* <ul className="progress_bar">
                  <li className="active">
                    <div className="step_data">
                      <p className="step_info">Offer Created</p>
                      <p className="step_date">01/03/2023</p>
                    </div>
                    <span className="fix_border"></span>
                   </li>
                   <li className="active">
                    <div className="step_data">
                      <p className="step_info">Offer Submitted</p>
                      <p className="step_date">01/03/2023</p>
                    </div>
                    <span className="fix_border"></span>
                  </li>
                  <li className=""> 
                    <div className="step_data">
                      <p className="step_info">Submittal Completed</p>
                      <p className="step_date">02/03/2023</p>
                    </div>
                    <span className="fix_border"></span>
                  </li>
                  <li className="">
                       <div className="step_data">
                        <p className="step_info">Drawing Completed</p>
                        <p className="step_date">03/03/2023</p>
                      </div>
                      <span className="fix_border"></span>
                  </li>
                  <li  className="">
                       <div className="step_data">
                        <p className="step_info">Won</p>
                        <p className="step_date"></p>
                      </div>
                      <span className="fix_border"></span>
                  </li>
                  <li  className="">
                       <div className="step_data">
                        <p className="step_info">Dispatched</p>
                        <p className="step_date"></p>
                      </div>
                      <span className="fix_border"></span>
                  </li>
                </ul> */}
            </div>
        </div>)
          }
           
        </>
    );
}
export default ShowOrderDetail;