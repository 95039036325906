import { BrowserRouter, Routes, Route, Router } from 'react-router-dom';

import { Error, Register, VerifyEmail, ResetPassword, CustomerList, UserList, StockPumpMaster, ViewPumpResults, AddNewPump, EnquiresList, EnquiriesDetail, SalesOrderList, SalesOrderListDetail, SalesOrderTracking, ProtectedRoute, CustomerListMobile, WorkInProgress, FirePumpStockBooking, FirePumpStockBookingMobile, FirePumpCompare, MyOrder, OrderDetail, ImportedStockPump,EditPump,ImportedStockPumpAvailability,PumpDetails  } from './pages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  SelectPump,
  SelectPumpDetail,
  SelectPumpList,
  Home,
  SharedLayout,
  SelectPumpListMobile, TrackOrder 
} from './pages/dashboard';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <SharedLayout />}
        >
          
          {/* <Route index element={<Home />} /> */}
          <Route exact index path='select-pump' element={<SelectPump />} />
          <Route exact path='select-pump-detail' element={<SelectPumpDetail />} />
          <Route exact path='select-pump-list' element={<SelectPumpList />} />
          <Route exact path='select-pump-list-next' element={<SelectPumpList />} />
          <Route exact path='select-pump-list-mobile' element={<SelectPumpListMobile />} />
          <Route exact path='stock-availability' element={<FirePumpStockBooking />} />
          <Route exact path='stock-availability-mob' element={< FirePumpStockBookingMobile />} />
          <Route exact path='fire-pump-compare' element={<FirePumpCompare />} />
          <Route exact path='view-pump-data' element={<PumpDetails/>} />
          
        </Route>
        <Route
          path='/'
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route path='customer-list' element={<CustomerList />} />
          <Route path='customer-list-mobile' element={<CustomerListMobile />} />
          <Route path='user-list' element={<UserList />} />
          <Route path='work-in-progress' element={<WorkInProgress />} />      
          <Route path='stock-pump-master' element={<StockPumpMaster />} />
          <Route path='view-pump-results/:string' element={<ViewPumpResults/>} />
          <Route path='add-new-pump' element={<AddNewPump />} />
          <Route path='enquires-list' element={<EnquiresList />} />
          <Route path='enquires-detail' element={<EnquiriesDetail />} />
          <Route path='sales-order-list' element={<SalesOrderList />} />
          <Route path='sales-order-detail' element={<SalesOrderListDetail/>} />
          
          <Route path='imported-stock-pump' element={<ImportedStockPump/>} /> 
          <Route path='edit-stock-pump/:string' element={<EditPump/>} />
          <Route path='imported-stock-pump-availability' element={<ImportedStockPumpAvailability/>} /> 
          
          <Route exact path='my-order' element={<MyOrder />} />
          <Route exact path='order-detail' element={<OrderDetail />} />
          <Route exact path='sales-order-tracking' element={<SalesOrderTracking/>} />
        </Route>
        
        <Route exact path='/register' element={<Register />} />
        <Route exact path='/login' element={<Register />} />
        <Route exact path='/forgot-password' element={<Register />} />
        <Route exact path='reset-password' element={<ResetPassword />} />
        <Route exact path='verify-email' element={<VerifyEmail />} />       
        <Route path='*' element={<Error />} />
      </Routes>
      <ToastContainer position='top-center' />
    </BrowserRouter>
  );
}

export default App;
