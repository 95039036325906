import { Outlet } from 'react-router-dom';
import { Navbar } from '../../components';
import { Footer } from '../../components';
import Wrapper from '../../assets/wrappers/SharedLayout';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SharedLayout = () => {
  const location = useLocation();
  const { user } = useSelector((store) => store.user);
  if (!user) {
    if(location.pathname === '/')
      return <Navigate to='/select-pump' />;
  } else {
    if(location.pathname === '/') {
      if (user.role === 'customer') {
        return <Navigate to='/select-pump' />;
      } else if (user.role === 'sales') {
        return <Navigate to='/work-in-progress' />;
      } else {
        return <Navigate to='/customer-list' />;
      }
    }
  }
  const hideFooterPaths = ['/sales-order-tracking'];
  return (
    <Wrapper>
      <main className='dashboard'>
        <div>
          <Navbar />
          <div className='dashboard_page_main'>
            <Outlet />
          </div>
          {!hideFooterPaths.includes(location.pathname) && <Footer />}
        </div>
      </main>
    </Wrapper>
  );
};
export default SharedLayout;
